@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #1F3BB3; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .spinner-container {
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    bottom: 0;
    display: block;
    z-index: 100;
  }

  .spinner-container [data-testid="oval-loading"] {
    position: absolute;
    display: inline-flex;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }