body.modal-open {
    height: 100vh;
    overflow-x: visible !important;
}

.modal-title {
    color: #010101;
    text-transform: capitalize;
    font-size: 1.125rem;
    font-weight: 600;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
    height: auto;
    width: 100%;
}

.nav-link.active,
.nav-link.active > i.menu-icon {
    background-color: #FFFFFF;
    border-radius: 0px 20px 20px 0px;
    color: #1F3BB3 !important;
    font-weight: bold !important;
}

.global-overlay-bg {
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 10000 !important;
}

.main-panel-blank {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
}

.welcome-text span {
    white-space: nowrap;
}

.icon-rotate {
    position: relative;
    transform: rotate(90deg);
}
.icon-color {
    color: var(--bs-link-color);
}

.btn.dropdown-item {
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}

.fs-xs {
    font-size: 12px;
}

/* quill editor */
.ql-editor {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    min-height: 200px;
}
.ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0;
}
.ql-container.ql-snow,
.ql-toolbar.ql-snow {
    border-color:#dee2e6 !important;
}
.ql-container {
    border-radius: 0 0 4px 4px;
}

/* table */
table td .dropdown-item {
    display: flex;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 0.875rem;
    width: 100%;
    align-items: center;
    justify-content: center;

}

table td .btn-group > .btn {
    border-radius: 5px !important;
    padding: 10px 20px;
    transition: all linear 0.2s;
}



table tr:hover td .btn-group > .btn {
    background-color: #1F3BB3;
    box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 4px 16px 0 rgba(167, 175, 183, 0.33) ;
}
table tr:hover td .btn-group > .btn svg {
    color: #FFFFFF;
}

/* table responsive (custom) */
.table-responsive {
    min-height: 212px;
}
.table-responsive .table tr:hover td {
    background-color: #eaeaf1;
}
.table-responsive .table th {
    font-size: 13px;
    padding: 20px 10px;
}
.table-responsive .table td {
    height: 38px;
    padding: 5px 10px;
}
.table-responsive .table td:last-child {
    text-align: center;
    width: 60px;
}
.table-responsive .table td.col-select {
    padding-top: 0;
    padding-bottom: 0;
    width: 200px;
}
.table-responsive .table td.col-select .select-custom {
    font-size: 12px;
    font-weight: bold;
}
.table-responsive .table td.col-select .select-custom [class^="-indicatorContainer"],
.table-responsive .table td.col-select .select-custom [class*="-indicatorContainer"] {
    padding: 0 8px;
}
.table-responsive .table td.col-currency {
    padding-top: 0;
    padding-bottom: 0;
    width: 250px;
}
.table-responsive .table td .form-control {
    font-size: 12px;
    font-weight: bold;
}
.table-responsive .table .row-level-0 td {
    background: var(--bs-table-striped-bg);
}
.table-responsive .table .row-level-0 td:nth-child(1) {
    text-transform: uppercase;
    font-weight: bold;
}
.table-responsive .table .row-level-1 td:nth-child(2) {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: bold;
}
.table-responsive .table .row-level-2 td:nth-child(2) {
    font-size: 12px;
    text-transform: capitalize;
}

/* table create account */
.table-responsive table.table-account-create td:first-child {
    width: 120px;
}

/* MUIDataTable */
.MuiPaper-root,
.MuiPaper-root table.MuiTable-root,
.MuiPaper-root table.MuiTable-root th,
.MuiPaper-root table.MuiTable-root td {
    font-family: Manrope, sans-serif;
}
.MuiPaper-root [class^="-MUIDataTable-responsiveBase"],
.MuiPaper-root [class*="-MUIDataTable-responsiveBase"] {
    min-height: 212px;
}
table.MuiTable-root th {
    font-size: 13px;
    font-weight: 600;
    padding: 10px;
    text-transform: capitalize;
}
table.MuiTable-root th button {
    background-color: transparent !important;
    padding-left: 0;
    padding-right: 0;
    font-size: 13px;
    font-family: Manrope, sans-serif;
    font-weight: 600;
    text-transform: capitalize;
}
table.MuiTable-root td {
    font-size: 13px;
    padding: 5px 10px;
}
table.MuiTable-root td:first-child {
    font-weight: 600;
}
table.MuiTable-root td:last-child {
    text-align: center;
    width: 60px;
}
table.MuiTable-root td img {
    height: auto;
    width: 100%;
    max-width: 40px;
}
table.MuiTable-root td .col-img {
    flex: 0 0 auto;
    width: 50px;
}
table.MuiTable-root td .col-img ~ .col {
    width: calc(100% - 50px);
}
table.MuiTable-root p {
    margin: 0;
}
table.table-companies td:first-child {
    font-weight: bold;
    width: 300px;
}

/* table journal */
table.table-journal td,
table.table-journal td p {
    font-size: 11px;
    font-weight: bold !important;
}

/* form */
.form-group label {
    font-weight: bold;
}

.form-group .form-control {
    padding: 0 1rem;
}
.form-group .form-control:read-only {
    color: #8D8D8D;
    font-weight: bold;
    background-color: #EBEDF2;
}
.form-group textarea.form-control {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 200px;
}

/* select custom */
.select-custom {
    font-size: 14px;
}

.select-custom [class*="-placeholder"],
.select-custom [class^="-placeholder"] {
    color: #C9C8C8;
}
.select-custom > [class*="-control"],
.select-custom > [class*="-control"] {
    border-color: #dee2e6;
    min-height: 32px;
}
.select-custom  [class*="-indicatorContainer"],
.select-custom [class*="-indicatorContainer"] {
    padding: 5px;
}
.select-custom #react-select-3-placeholder {
    padding-left: 8px;
    margin-right: 8px;
    margin: 0;
}
.select-custom .css-fdbcdi-singleValue,
.select-custom .css-qbdosj-Input {
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
}

/* File Label */
label.file-label {
    position: relative;
    overflow: hidden;
    width: 100%;
}
label.file-label img {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
    height: auto;
    max-width: 100%;
    width: 100%;
}
label.file-label img ~ .file-cta {
    background-color: transparent;
    padding: 8px;
    min-height: auto;
}
label.file-label .file-input {
    display: none;
    transform: translate(-300px);
}
label.file-label .file-cta {
    background: #f0f0f0;
    border-radius: 4px;
    padding: 20px;
    min-height: 200px;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;
}
label.file-label svg {
    color: #1F3BB3;
    margin-right: 10px;
}

/* tabs */
.tabs-no-border ~ .tab-content {
    border: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}

.tabs-no-border ~ .tab-content table td {
    font-weight: 500;
}

/* card */
.card .card-description {
    line-height: 1rem;
}

/* list breakdown */
ul.list-breakdown {
    padding-left: 20px;
    position: relative;
    list-style-type: none;
    margin-bottom: 0;
}
ul.list-breakdown::before {
    content: "";
    border: 1px solid #dee2e6;
    position: absolute;
    top: 9px;
    bottom: 13px;
    left: 0;
}
ul.list-breakdown ul.list-breakdown::before {
    content: "";
    border: 1px solid #dee2e6;
    position: absolute;
    top: 9px;
    bottom: 13px;
    left: 0;
}
ul.list-breakdown ul.list-breakdown > li::before {
    top: 9px;
}
ul.list-breakdown > li {
    position: relative;
}
ul.list-breakdown > li::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    width: 13px;
    height: 13px;
    left: -25px;
    top: 9px;
    border: 4px solid #E9EDFB;
    margin-right: 15px;
    z-index: 2;
    background: #1F3BB3;
}
ul.list-breakdown .row {
    align-items: center;
    margin-bottom: 4px;
}
ul.list-breakdown .row .col {
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    padding: 5px 8px;
    min-height: 34px;
}
ul.list-breakdown .has-bg {
    font-weight: bold;
}
ul.list-breakdown .row.has-bg .col {
    background-color: #D2D8F0;
    text-transform: uppercase;
}
ul.list-breakdown .row.has-bg .col .icon {
    color: #1F3BB3;
}
ul.list-breakdown .row:not(.has-bg) .col {
    background-color: #F7F7F7;
    text-transform: capitalize;
}
ul.list-breakdown .row:not(.has-bg) .col .icon {
    color: #34B1AA;
}
ul.list-breakdown .col-code {
    font-weight: bold;
    flex: 0 1 130px;
}
ul.list-breakdown .col-actions {
    padding: 0 !important;
    flex: 0 1 40px;
    justify-content: end;
}
ul.list-breakdown .col > .btn-group > .btn.btn-link {
    border-radius: 6px;
    padding: 0 !important;
    line-height: 34px;
    height: 34px;
    transition: all linear 0.3s;
    width: 34px;
}
ul.list-breakdown .row:hover .col > .btn-group > .btn.btn-link {
    background-color: #1F3BB3;
    color: #FFFFFF;
    box-shadow: var(--bs-box-shadow) !important;
}
ul.list-breakdown .col .icon {
    margin-right: 10px;
}
ul.list-breakdown .col .dropdown-menu .btn {
    text-align: center;
}
